import React from "react";
import "./ProjectCard.css";

const ProjectCard = ({ title, summary, tags, imageUrl }) => {
  return (
    <figure className="project-card">
      {imageUrl && <img src={imageUrl} alt={title} className="project-image" />}
      <figcaption>
        <h3 className="project-title">{title}</h3>
        <p className="project-summary">{summary}</p>
        <div className="project-tags">
          {tags.map((tag) => (
            <span key={tag} className="project-tag">
              {tag}
            </span>
          ))}
        </div>
      </figcaption>
    </figure>
  );
};

export default ProjectCard;
