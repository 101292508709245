import React, { useState, useEffect } from "react";
import NavigationTabs from "./NavigationTabs";
import image1 from "../assets/images/ART/IA/01.png";
import image2 from "../assets/images/ART/IA/02.png";
import image3 from "../assets/images/ART/IA/03.png";
import image4 from "../assets/images/ART/IA/04.png";
import image5 from "../assets/images/ART/IA/05.png";
import image6 from "../assets/images/ART/IA/06.png";

import image7 from "../assets/images/ART/IA/07.webp";
import image8 from "../assets/images/ART/IA/08.webp";
import image9 from "../assets/images/ART/IA/09.webp";
import image10 from "../assets/images/ART/IA/10.webp";
import image11 from "../assets/images/ART/IA/11.webp";
import image12 from "../assets/images/ART/IA/12.webp";

import image13 from "../assets/images/ART/IA/13.webp";
import image14 from "../assets/images/ART/IA/14.webp";
import image15 from "../assets/images/ART/IA/15.webp";
import image16 from "../assets/images/ART/IA/16.webp";
import image17 from "../assets/images/ART/IA/17.webp";
import image18 from "../assets/images/ART/IA/18.webp";

import imagePhoto1 from "../assets/images/ART/PHOTOS/01.jpg";
import imagePhoto2 from "../assets/images/ART/PHOTOS/02.jpg";
import imagePhoto3 from "../assets/images/ART/PHOTOS/03.jpg";
import imagePhoto4 from "../assets/images/ART/PHOTOS/04.jpg";
import imagePhoto5 from "../assets/images/ART/PHOTOS/05.jpg";
import imagePhoto6 from "../assets/images/ART/PHOTOS/06.jpg";

import imagePhoto7 from "../assets/images/ART/PHOTOS/07.jpg";
import imagePhoto8 from "../assets/images/ART/PHOTOS/08.jpg";
import imagePhoto9 from "../assets/images/ART/PHOTOS/09.jpg";
import imagePhoto10 from "../assets/images/ART/PHOTOS/10.jpg";
import imagePhoto11 from "../assets/images/ART/PHOTOS/11.jpg";
import imagePhoto12 from "../assets/images/ART/PHOTOS/12.jpg";

import imageMain1 from "../assets/images/ART/MAINS/01.jpg";

import "./ArtPage.scss";
import { Canvas } from "@react-three/fiber";
import CubeImage from "../app/CubeImage";

const imagesIA_1 = [image1, image2, image3, image4, image5, image6];
const imagesIA_2 = [image7, image8, image9, image10, image11, image12];
const imagesIA_3 = [image13, image14, image15, image16, image17, image18];

const imagesPhoto_1 = [
  imagePhoto1,
  imagePhoto2,
  imagePhoto3,
  imagePhoto4,
  imagePhoto5,
  imagePhoto6,
];
const imagesPhoto_2 = [
  imagePhoto7,
  imagePhoto8,
  imagePhoto9,
  imagePhoto10,
  imagePhoto11,
  imagePhoto12,
];

const imagesMain_1 = [
  imageMain1,
  imageMain1,
  imageMain1,
  imageMain1,
  imageMain1,
  imageMain1,
];

const ArtPage = ({ isAnimationDone }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selectedImageSrc, setSelectedImageSrc] = useState(null);

  const imageSets = [
    imagesIA_1,
    imagesIA_2,
    imagesIA_3,
    imagesPhoto_1,
    imagesPhoto_2,
    imagesMain_1,
  ];

  const handleFaceClick = (imageSetIndex, faceIndex) => {
    const selectedSet = imageSets[imageSetIndex];
    if (selectedSet && selectedSet[faceIndex]) {
      setSelectedImageSrc(selectedSet[faceIndex]);
    }
  };

  return (
    <div>
      <nav>
        <NavigationTabs currentFace={3} />
      </nav>
      <div className="page-content">
        {isMobile ? (
          <div className="mobile-container">
            {imageSets.map((imageSet, index) =>
              imageSet.map((imageSrc, imageIndex) => (
                <img
                  key={`${index}-${imageIndex}`}
                  src={imageSrc}
                  alt="Art"
                  onClick={() => handleFaceClick(index, imageIndex)}
                  style={{ width: "100%", marginBottom: "10px" }}
                />
              ))
            )}
          </div>
        ) : (
          <div className="art-container">
            <div className="canvas-wrapper">
              <h1>Créations via AI</h1>
              <Canvas style={{ height: "400px", width: "900px" }}>
                <ambientLight intensity={1.5} />
                <pointLight position={[10, 10, 30]} intensity={0.1} />
                <CubeImage
                  images={imagesIA_1}
                  onFaceClick={(faceIndex) => handleFaceClick(0, faceIndex)}
                  position={[-4, 0, 0]}
                />
                <CubeImage
                  images={imagesIA_2}
                  onFaceClick={(faceIndex) => handleFaceClick(1, faceIndex)}
                  position={[0, 0, 0]}
                />
                <CubeImage
                  images={imagesIA_3}
                  onFaceClick={(faceIndex) => handleFaceClick(2, faceIndex)}
                  position={[4, 0, 0]}
                />
              </Canvas>
            </div>

            <div className="canvas-wrapper">
              <h1>Photographie</h1>
              <Canvas style={{ height: "400px", width: "900px" }}>
                <ambientLight intensity={1.5} />
                <pointLight position={[10, 10, 30]} intensity={0.1} />
                <CubeImage
                  images={imagesPhoto_1}
                  onFaceClick={(faceIndex) => handleFaceClick(3, faceIndex)}
                  position={[2, 0, 0]}
                />
                <CubeImage
                  images={imagesPhoto_2}
                  onFaceClick={(faceIndex) => handleFaceClick(4, faceIndex)}
                  position={[-2, 0, 0]}
                />
              </Canvas>
            </div>

            <div className="canvas-wrapper">
              <h1>Créations à la main</h1>
              <Canvas style={{ height: "400px", width: "900px" }}>
                <ambientLight intensity={1.5} />
                <pointLight position={[10, 10, 30]} intensity={0.1} />
                <CubeImage
                  images={imagesMain_1}
                  onFaceClick={(faceIndex) => handleFaceClick(5, faceIndex)}
                  position={[0, 0, 0]}
                />
              </Canvas>
            </div>

            {selectedImageSrc !== null && (
              <div className="lightbox">
                <img
                  src={selectedImageSrc}
                  onClick={() => setSelectedImageSrc(null)}
                  alt="Creation"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtPage;
