import React from "react";
import NavigationTabs from "./NavigationTabs";
import ProjectCard from "../components/ProjectCard";
import VeroCrea from "../assets/images/projet/veroCrea.png";
import PortfoImage from "../assets/images/projet/portfolio.png";

const WebDevPage = () => {
  const tagsPortfolio = ["Web", "Javascript", "React"];
  const tagsVero = ["Web", "React"];
  return (
    <div>
      <nav>
        <NavigationTabs currentFace={1} />
      </nav>
      <div className="page-content">
        <h1>WebDev</h1>
        <a href="https://www.vero-crea.fr">
          <ProjectCard
            title={"Véro Créa"}
            summary={"Site vitrine d'une créatrice. Pour ce projet j'ai utilisé React, l'API Google MAP et Helmet pour le SEO"}
            tags={tagsVero}
            imageUrl={VeroCrea}
          />
        </a>
        <a href="https://www.atsumeru.fr">
          <ProjectCard
            title={"Portfolio"}
            summary={"Portfolio personnel. Pour ce projet j'ai utilisé React, des animations CSS, de la 3D pour le cube et la gestion du viewport pour la responsive."}
            tags={tagsPortfolio}
            imageUrl={PortfoImage}
          />
        </a>
      </div>
    </div>
  );
};

export default WebDevPage;
