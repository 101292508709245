import React from "react";
import NavigationTabs from "./NavigationTabs";
import "./PresentationPage.scss";
import Photo from "../assets/images/DSCF8205.jpg";

const PresentationPage = () => {
  return (
    <div>
      <nav>
        <NavigationTabs currentFace={4} />
      </nav>
      <div className="page-content">
        <div className="presentation">
          <img src={Photo} alt="Mathieu Genet" className="profile-photo" />
          <div className="presentation-text">
            <h1>Bonjour, je suis Mathieu Genet.</h1>
            <p>
              Âgé de 31 ans, je suis actuellement en formation d'Intégrateur Web
              sur OpenClassroom, avec un diplôme en communication graphique en
              poche. Depuis l'âge de 14 ans, je développe une passion pour la
              création numérique, me lançant dans des projets personnels qui ont
              forgé mon expérience en développement.
            </p>
            <p>
              Je suis aussi un passionné de macro-photographie et d'art,
              m'aventurant récemment dans l'exploration du web art via
              l'intelligence artificielle générative. Mes compétences en
              développement sont variées, couvrant à la fois le Front-End avec
              React, SASS, CSS, et JavaScript, ainsi que le Back-End avec
              Node.js et Express.
            </p>
            <p>
              En tant qu'aficionado de jeux vidéo, j'ai également travaillé sur
              des projets de développement mobile en utilisant React Native via
              Expo et de développement sur PC avec le moteur Unity et C#.
            </p>
            <p>
              Curieux et touche-à-tout, j'aime expérimenter et explorer de
              nouvelles avenues pour réaliser des solutions créatives et
              efficaces. Je suis toujours prêt à relever de nouveaux défis et à
              élargir mon horizon dans le vaste monde de la technologie et de la
              création numérique.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationPage;
