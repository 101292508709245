import React from "react";
import NavigationTabs from "./NavigationTabs";
import ProjectCard from "../components/ProjectCard";
import AludreImage from "../assets/images/projet/aludre.webp";
import AtsumeruImage from "../assets/images/projet/atsumeru.webp";

const GameDevPage = () => {
  const tagsAtsumeru = ["Mobile", "Node.JS", "Javascript"];
  const tagsAludre = ["C#", "Unity", "PC"];
  return (
    <div>
      <nav>
        <NavigationTabs currentFace={2} />
      </nav>
      <div className="page-content">
        <h1>GameDev</h1>
        <ProjectCard
          title={"Projet Aludre"}
          summary={"Projet en cours"}
          tags={tagsAludre}
          imageUrl={AludreImage}
        />
        <ProjectCard
          title={"Projet Atsumeru"}
          summary={"Projet en cours"}
          tags={tagsAtsumeru}
          imageUrl={AtsumeruImage}
        />
      </div>
    </div>
  );
};

export default GameDevPage;
