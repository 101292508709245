import React from "react";
import { useNavigate } from "react-router-dom";
import "./NavigationTabs.scss";

const NavigationTabs = ({ currentFace }) => {
  const navigate = useNavigate();

  const tabs = [
    { id: "Accueil", route: "/" },
    { id: "Web-dev", route: "/web_dev" },
    { id: "Game-dev", route: "/game_dev" },
    { id: "Art", route: "/art" },
    { id: "Présentation", route: "/presentation" },
    { id: "Contact", route: "/contact" },
  ];

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div className="navigation-tabs">
      {tabs.map((tab, index) => (
        <button
          key={tab.id}
          onClick={() => handleNavigation(tab.route)}
          className={currentFace === index ? "active" : ""}
        >
          {tab.id}
        </button>
      ))}
    </div>
  );
};

export default NavigationTabs;
