import React from "react";
import NavigationTabs from "./NavigationTabs";
import ProjectCard from "../components/ProjectCard";
import { Link } from "react-router-dom";

const HomePage = () => {
  const tagsDevWeb = ["Web", "React", "Dev", "Node.js", "Javascript"];
  const tagsJV = [
    "Dev",
    "Node.js",
    "Javascript",
    "Mobile",
    "React Native",
    "C#",
    "Unity",
  ];
  const tagsIlu = ["Art", "Photo"];
  const tagsPre = ["Présensation"];
  const tagsCon = ["Contact", "Réseaux"];
  return (
    <div>
      <nav>
        <NavigationTabs currentFace={0} />
      </nav>
      <div className="page-content">
        <p>Bienvenue dans mon Portfolio, Vous pouvez retrouver ici :</p>
        <Link to="/web_dev">
          <ProjectCard
            title={"Développement Web"}
            summary={"Mes différentes créations web"}
            tags={tagsDevWeb}
            imageUrl={""}
          />
        </Link>
        <Link to="/game_dev">
          <ProjectCard
            title={"Développement JV"}
            summary={"Mes différentes créations dans le domaine jeux vidéos"}
            tags={tagsJV}
            imageUrl={""}
          />
        </Link>
        <Link to="/art">
          <ProjectCard
            title={"Illustrations et Photos"}
            summary={"Mes différentes réalisations artistiques et photographiques"}
            tags={tagsIlu}
            imageUrl={""}
          />
        </Link>
        <Link to="/presentation">
          <ProjectCard
            title={"Présentation"}
            summary={"Ma présentation"}
            tags={tagsPre}
            imageUrl={""}
          />
        </Link>
        <Link to="/contact">
          <ProjectCard
            title={"Contact"}
            summary={"Mes différents réseaux et possibilités de me contacté"}
            tags={tagsCon}
            imageUrl={""}
          />
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
