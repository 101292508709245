import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Cube from "./Cube";
import useCubeNavigation from "./useCubeNavigation";
import "./App.css";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <MainContent />
      </div>
    </Router>
  );
}

const Header = () => {
  return <header></header>;
};

const MainContent = () => {
  const currentFace = useCubeNavigation();
  return (
    <main>
      <Cube currentFace={currentFace} />
    </main>
  );
};

export default App;
