import React, { useRef, useState, useEffect } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { TextureLoader, MeshLambertMaterial, Raycaster, Vector2 } from "three";

const CubeImage = ({ images, onFaceClick, position }) => {
  const { camera } = useThree();
  const boxRef = useRef();
  const textures = useLoader(TextureLoader, images);
  const [isDragging, setIsDragging] = useState(false);
  const [initialMousePosition, setInitialMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });

  useFrame(() => {
    if (!isDragging) {
      boxRef.current.rotation.x += 0.008;
    }
  });

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isDragging) {
        const dx = (event.clientX - lastMousePosition.x) * 0.01;
        const dy = (event.clientY - lastMousePosition.y) * 0.01;

        boxRef.current.rotation.y += dx;
        boxRef.current.rotation.x += dy;

        setLastMousePosition({
          x: event.clientX,
          y: event.clientY,
        });
      }
    };

    const handleMouseUp = (event) => {
      if (isDragging) {
        const moveDistance = Math.sqrt(
          Math.pow(lastMousePosition.x - initialMousePosition.x, 2) +
            Math.pow(lastMousePosition.y - initialMousePosition.y, 2)
        );

        if (moveDistance < 5) {
          const rect = event.target.getBoundingClientRect();
          const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
          const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

          const raycaster = new Raycaster();
          raycaster.setFromCamera(new Vector2(x, y), camera);
          const intersects = raycaster.intersectObject(boxRef.current, true);

          if (intersects.length > 0) {
            const faceIndex = intersects[0].faceIndex;
            const sideIndex = Math.floor(faceIndex / 2);
            onFaceClick(sideIndex);
          }
        }
        setIsDragging(false);
        document.body.style.userSelect = "";
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    isDragging,
    initialMousePosition,
    lastMousePosition,
    onFaceClick,
    camera,
  ]);

  const handlePointerDown = (event) => {
    setIsDragging(true);
    const mousePosition = { x: event.clientX, y: event.clientY };
    setInitialMousePosition(mousePosition);
    setLastMousePosition(mousePosition);
    document.body.style.userSelect = "none";
  };

  const materials = textures.map(
    (texture) => new MeshLambertMaterial({ map: texture })
  );

  return (
    <mesh
      ref={boxRef}
      position={position}
      onPointerDown={handlePointerDown}
      material={materials}
    >
      <boxGeometry args={[2, 2, 2]} />
    </mesh>
  );
};

export default CubeImage;
