import React from "react";
import NavigationTabs from "./NavigationTabs";
import "./ContactPage.scss";

const ContactPage = () => {
  return (
    <div>
      <nav>
        <NavigationTabs currentFace={5} />
      </nav>

        <div className="page-content">
            <h1>Contact</h1>
            <ul className="contact-list">
              <li>instagram à venir</li>
              <li>facebook à venir</li>
              <li>
                <a
                  href="https://www.flickr.com/people/192911155@N02/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Flickr
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/mathieu-genet/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="mailto:math.genet@gmail.com">Email</a>
              </li>
            </ul>
        </div>
    </div>
  );
};

export default ContactPage;
