// useCubeNavigation.js
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const useCubeNavigation = () => {
  const [currentFace, setCurrentFace] = useState(0);
  const location = useLocation();

  const pathToFace = {
    '/': 0,
    '/web_dev': 1,
    '/game_dev': 2,
    '/art': 3,
    '/presentation': 4,
    '/contact': 5,
  };

  useEffect(() => {
    const faceIndex = pathToFace[location.pathname];
    if (faceIndex !== undefined) {
      setCurrentFace(faceIndex);
    }
  }, [location]);

  return currentFace;
};

export default useCubeNavigation;
