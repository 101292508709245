import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Cube.scss";

import HomePage from "./pages/HomePage";
import ArtPage from "./pages/ArtPage";
import ContactPage from "./pages/ContactPage";
import GameDevPage from "./pages/GameDevPage";
import PresentationPage from "./pages/PresentationPage";
import WebDevPage from "./pages/WebDevPage";

const Cube = () => {
  const location = useLocation();
  const [isZoomedOut, setIsZoomedOut] = useState(false);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [currentFace, setCurrentFace] = useState(0);
  const [isAnimationDone, setIsAnimationDone] = useState(false);

  const pathToFace = {
    "/": 0,
    "/web_dev": 1,
    "/game_dev": 2,
    "/art": 3,
    "/presentation": 4,
    "/contact": 5,
  };

  useEffect(() => {
    const newFace = pathToFace[location.pathname];
    console.log(newFace);
    if (newFace !== undefined) {
      setIsZoomedOut(true);
      setIsAnimationDone(false);
      const newRotation = calculateRotation(newFace);
      setTimeout(() => {
        setRotation(newRotation);
        setTimeout(() => {
          setCurrentFace(newFace);
          setIsZoomedOut(false);

          setIsAnimationDone(true);
        }, 1000);
      }, 1000);
    }
  }, [location]);

  const calculateRotation = (faceIndex) => {
    const rotations = [
      { x: 0, y: 0 },
      { x: 0, y: -90 },
      { x: 0, y: 180 },
      { x: 0, y: 90 },
      { x: -90, y: 0 },
      { x: 90, y: 0 },
    ];

    return rotations[faceIndex];
  };

  const faceComponents = [
    <HomePage />,
    <WebDevPage />,
    <GameDevPage />,
    <ArtPage isAnimationDone={isAnimationDone} />,
    <PresentationPage />,
    <ContactPage />,
  ];

  return (
    <div className={`scene ${isZoomedOut ? "zoom-out" : ""}`}>
      <div
        className={`cube turning`}
        style={{
          "--rotate-x": `${rotation.x}deg`,
          "--rotate-y": `${rotation.y}deg`,
        }}
      >
        {faceComponents.map((FaceComponent, index) => (
          <div
            className={`cube-face face-${index} ${
              index === currentFace ? "active" : ""
            }`}
            key={index}
          >
            {FaceComponent}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cube;
